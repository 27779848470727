import axios from "axios";
// import { json } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URL;


export const fetchgetCaterogoriasEspeciales = async () => {
    const url = `${apiUrl}/getCategoriasEspeciales`
    try {
        const response = await axios.get(url)
        return response;
    } catch (error) {
        console.log('hubo un error al obtener las categorías especiales: ', error)
        throw error 
    }
}

// export const fetchPostHighlightProduct = async (data) => {
//     const url = `${apiUrl}/registerHighlightProduct`
//     try {
//         const response = await axios.post(url, data, {
//             headers: {
//                 'Content-Type': 'application/json',
//             }
//         })
//         console.log('Respuesta fetch de creación : ', response);
//         return response.data;
//     } catch (error) {
//         console.log('hubo un error:', error)
//         throw error;
//     }
// }

// export const fetchUpdateHighlightProduct = async (data) => {
//     const url = `${apiUrl}/updateHighlightProduct/`
//     console.log('URL', url)
//     try {
//         const response = await axios.patch(url, data, {
//             headers: {
//                 'Content-Type': 'application/json',
//             }
//         })
//         console.log('Respuesta fetch de actualización: ', response);
//         return response.data;
//     } catch (error) {
//         console.log('hubo un error:', error)
//         throw error;
//     }
// }

// export const fetchDeleteHighlightProduct = async (id) => {
//     const url = `${apiUrl}/deleteHighlightProduct/`
//     try {
//         const response = await axios.delete(url,{
//             data:{id},
//             headers: {
//                 'Content-Type': 'application/json',
//             }
//         })
//         console.log('Respuesta del fetch de eliminación : ', response);
//         return response.data;
//     } catch (error) {
//         console.log('hubo un error:', error)
//         throw error;
//     }
// }