import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import HeaderPrincipal from './headerPrincipal';
import Categories from './categories';
const CustomGrid = styled(Grid)({
  height: '50%',
});

export default function Header({

  setSectionSelected
}) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '275px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid container  >
        <HeaderPrincipal
          item xs={12} />
      </Grid>
      <CustomGrid>
        <Grid container sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <Categories
            item xs={12} />
        </Grid>
      </CustomGrid>
    </Box>
  );
}