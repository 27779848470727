import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;


export const fetchRegisterUser = async (data) => {
    let url = `${apiUrl}/createUser`
    try {
        await axios.post(url, data)
        return true
    } catch (error) {
        console.log('hubo un error al registrar usuario: ', error)
        throw error 
    }
}