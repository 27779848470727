import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Typography, Box, Collapse, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useMainContext from '../components/Context/useMainContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchAllFamilies } from '../Macrosoft_API';
import GroupIcon from '@mui/icons-material/Group';
import CloseIcon from '@mui/icons-material/Close';
import CategoryIcon from '@mui/icons-material/Category';
function MenuLatMobile() {
  // const { categories, setCategories } = useMainContext();
  const [open, setOpen] = useState(false);
  const [openModalUserContainer, setOpenModalUserContainer] = useState(false)
  const [openCategory, setOpenCategory] = useState(null);
  const [loading, setLoading] = useState(false)
  const [adminCategories, setAdminCategories] = useState(false);
  const [categories, setCategories] = useState([])
  const toggleDrawer = () => {
    setOpen(!open);

  };
  const handleGetCategories = async () => {
    setLoading(true)
    try {
      const response = await fetchAllFamilies()
      console.log('respuesta del fetch: ', response)
      setCategories(response.data)
    } catch (error) {
      console.log('hubo un error en el fetch: ', error)
    } finally {
      setLoading(false)
    }
  }
  // const handelModal = () => {
  //   setAdminCategories(true);
  // }


  // const handleSubCategoryClick = async (id_subCategory) => {
  //   setLoading(true);
  //   try {
  //     // const articles = await fetchArticlesBySubCategory(id_subCategory);
  //     // setArticles(articles);
  //     // setShowMainCarrousel(false)
  //     toggleDrawer()
  //   } catch (error) {
  //     console.log('Error fetching articles:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleClick = () => {

    setAdminCategories(true);
    // try {
    //   // const response = await fetchSubCategories(id_category);
    //   // console.log('response articles: ', response);
    //   // setSubCategories(response);
    //   setOpenCategory(openCategory === id_category ? null : id_category);
    // } catch (error) {
    //   console.log('Hubo un error: ', error);
    // }
  };

  const closeModal = () => {
    setAdminCategories(!adminCategories);
  }
  useEffect(() => {
    handleGetCategories()
  }, [])

  return (

    <>
      <div>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon fontSize='large' />
        </IconButton>
        <Drawer
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          sx={{
            '& .MuiDrawer-paper': {
              width: '80%',
              boxSizing: 'border-box',
            },
          }}
        >
          <Box>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#E21B1B', padding: 1 }}>
              <Typography
                sx={{ color: 'white' }}
                variant='h4'>
                Categorias
              </Typography>
              <ArrowBackIcon
                sx={{ cursor: 'pointer', color: 'white' }}
                onClick={toggleDrawer} />
            </Grid>

            <List>
              {categories?.map(category => {
                return (
                  <ListItem button key={category.SupFlia}>
                    <ListItemIcon>
                      <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={category.Descripcion} />
                  </ListItem>
                )
              })}

            </List>
          </Box>
        </Drawer>
      </div>
    </>
  );
}

export default MenuLatMobile;
