import * as React from 'react';
import { useState } from 'react';
import { Box,Grid, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UserTable from './UserTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY: 'auto',
  p: 4,
  padding: 0,
  borderRadius: '10px'
};

export default function UserContainer({ openModal, handleClose }) {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid sx={{display:'flex',justifyContent:'space-between',backgroundColor:'#E21B1B',width:'100%',padding:1}}>
              <Typography variant='h5' sx={{color:'white'}}>
                GESTION DE USUARIOS
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleClose}
              >
                <CloseIcon sx={{color:'white'}} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid sx={{marginTop:1,padding:1}}>
            <UserTable/>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
