// import logo from './logo.svg';
import './App.css';
import SignInSide from '../src/login/SignInSide'
// import NavLateral from './dashboard/NavLateral';
// import { LoginOAuth } from './login/LoginOAuth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Index } from './erebil';
import { Provider } from './components/Context/MainContext'

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId="960710479294-vn4an88b2puhbpbjcpg6g273c9l1amu4.apps.googleusercontent.com">
        <Provider>
          <Router>
            <Routes>
              {/* <Route path="/" element={<NavLateral />} /> */}
              <Route path="/" element={<Index />} />
              <Route path="home" element={<SignInSide />} />
            </Routes>
          </Router>
        </Provider>
      </GoogleOAuthProvider>
    </div >
  );
}

export default App;
