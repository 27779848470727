import React from "react";
import { useState, useEffect } from "react";
import Header from "./headerContainer";
import Carrousel from "./carrousel";
import SectionAliados from "../components/Sections/SectionAliados";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import { ProductsCatalog } from '../components/ProductsCatalog';
import Typography from '@mui/joy/Typography';
import { Section } from "../components/Section";
import ContactFooter from "../components/ContactFooter";
import { ShopNavigator } from '../components/ShopNavigator/ShopNavigator';
import { fetchAllFamilies } from "../Macrosoft_API";
import useMainContext from "../components/Context/useMainContext";
import MenuLatMobile from "./MenuLatMobile";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoginModal from "../components/Login/modalLogin";
export const Index = () => {
    const { testContext,
        setSectionSelected,
        sectionSelected
    } = useMainContext()
    const sectionTexts = ["DAHUA", "Domotica", "Ofertas"];

    const [articles, setArticles] = useState([]);
    const [product, setProduct] = useState([])
    const [categories, setCategories] = useState([])
    const [openModalLogin, setOpenModalLogin] = useState(false)
    // const [optionShop, setoptionShop] = useState()
    const handleGetArticles = async () => {
        try {
            const response = await fetchAllFamilies()
            console.log('respuesta desde el fetch:', response)
            if (response?.data) {
                setArticles(response.data)
                // console.log('ES ESTE FETCH:', response.data)
            }
        } catch (error) {
            console.log('hubo un error en el fetch:', error)
        }
    }
    useEffect(() => {
        // console.log('desde context:', testContext)
        handleGetArticles()
    }, [])

    return (
        <>
            <Grid container spacing={1}>
                <Grid item sx={{ display: { xs: 'flex', sm: 'none' }, marginTop: '3%', marginLeft: '5%', justifyContent: 'space-between', width: '100%' }}>
                    <MenuLatMobile />
                    <Grid sx={{ display: 'flex', gap: 2, marginRight: '5%' }}>
                        <ShoppingCartIcon fontSize="large" />
                        <AccountCircleIcon
                            onClick={() => { setOpenModalLogin(true) }}
                            fontSize="large" />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Header />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '4rem' }}>
                    {/* Divider */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBottom: '15rem' }}>
                    {
                        sectionSelected == 'ShopNavigator' && (
                            <ShopNavigator
                            />)
                    }
                    {
                        sectionSelected == 'Carrousel' && (
                            <Carrousel
                                setSectionSelected={setSectionSelected}
                            />
                        )
                    }
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12}
                    sx={{
                        color: 'black',
                        py: 4,
                        mt: '5%',
                        boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        marginTop: {
                            xs: '100%',
                            sm: '',
                            md: '50%',
                            lg: '35%',
                        }
                    }}>
                    <Typography variant="h1" sx={{ color: 'black', fontSize: '2.5rem' }}>¡Productos Destacados!</Typography>
                    <Typography level="h4" color="danger" sx={{ mt: 1, fontWeight: 'xl' }}>{sectionTexts[0]}</Typography>
                    <ProductsCatalog search="DAHUA" listaPrecio="1" />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Section />
                </Grid>
                <Divider />
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <SectionAliados />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ContactFooter />
                </Grid>
            </Grid>
            <LoginModal
                openModalLogin={openModalLogin}
                setopenModalLogin={setOpenModalLogin}
            />
        </>
    )

}