import * as React from 'react';
import { useState, useEffect } from 'react';
// import { Grid, TextField, Button, Stack } from '@mui/material';
import { Grid, TextField, Stack, IconButton, Menu, MenuItem, Button, Badge, CircularProgress,Box } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './Assets/logo.png'
import perfilIcon from './Assets/perfil.png'
import carritoIcon from './Assets/carrito-de-compras.png'
import RegisterModal from '../components/modalRegister'
import LoginModal from '../components/Login/modalLogin'
import PreOrderModal from '../components/utils/PreOrderModal';
import useMainContext from '../components/Context/useMainContext';
import { fetchArticlesByFilter } from '../Macrosoft_API';
import MenuAdminContainer from '../components/AdminPanel/MenuAdminContainer';
const theme = createTheme({
    palette: {
        primary: {
            main: '#E21B1B', // Define tu color principal aquí
        },
    },
});
export default function HeaderPrincipal() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const [openModalRegister, setOpenModalRegister] = useState(false)
    const [openModalLogin, setOpenModalLogin] = useState(false)
    const [openModalPreOrder, setOpenModalPreOrder] = useState(false)
    const {
        setSectionSelected,
        sectionSelected } = useMainContext();


    const [search, setSearch] = useState('');
    // const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [loading, setLoading] = useState(false);
    const { setShowMainCarrousel, setArticles, articles, loguedUser, setLoguedUser, arrayProductShop,
        setArrayProductShop, setRol, rol } = useMainContext()

    // const handleSearch = async () => {
    //         setLoading(true);
    //         try {
    //             const response = await fetchArticlesByFilter({search});
    //             console.log('Respuesta de obtener artículos por búsqueda:', response);
    //             setArticles(response);
    //             setShowMainCarrousel(false);
    //         } catch (error) {
    //             console.log('Hubo un error', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    const handleSearch = async () => {
        setLoading(true);
        setSectionSelected('ShopNavigator')
        try {
            const response = await fetchArticlesByFilter({ search });
            console.log('Respuesta de obtener artículos por búsqueda:', response);
            setArticles(response);
            setShowMainCarrousel(false);
        } catch (error) {
            console.log('Hubo un error', error);
        } finally {
            setLoading(false);
        }
    };


    function LogoClick() {
        // setSectionSelected('Carrousel');
        window.location.reload();
    }

    useEffect(() => {
        if (search.trim() !== '') {
            handleSearch();
        }
    }, [search]);

   

    return (
        <ThemeProvider theme={theme}>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', marginLeft: '5%' }}>
                {/* Logo */}
                <Grid item xs={6} sm={6} md={2}>
                    <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => { LogoClick() }}
                        width={'80%'}
                        src={logo}
                        alt={'Erebil'}
                        loading="lazy"
                        height={'150px'}
                    />
                </Grid>

                {/* Iconos (Solo en xs) */}
                {/* <Grid item xs={12} sm={6} md={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <ShoppingCartIcon sx={{ fontSize: '3rem', margin: '1rem' }} />
                    <AccountCircleIcon sx={{ fontSize: '3rem', margin: '1rem' }} />
                </Grid> */}

                {/* Campo de búsqueda */}
                <Grid item xs={12} sm={6} md={7}>
                    <TextField
                        sx={{ width: '80%', marginTop: '2rem' }}
                        label="Buscador.."
                        value={search}
                        onChange={(e) => { setSearch(e.target.value) }}
                    />
                </Grid>

                {/* Iconos (No en xs) */}
                <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between', marginTop: '1rem', }}>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                        {/* Carrito Icon */}

                        <Badge
                            badgeContent={arrayProductShop?.length}
                            color="error"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    if (arrayProductShop.length > 0) {
                                        setOpenModalPreOrder(true)
                                    }
                                }
                                }
                            >
                                <img src={carritoIcon} alt="Carrito" style={{ width: '3rem', height: '3rem' }} />
                            </IconButton>
                        </Badge>
                        {/* Perfil Icon with Submenu */}
                        <div>
                            <IconButton
                                sx={{
                                    width: '4rem',                 // Ajusta el tamaño total del botón
                                    height: '4rem',
                                    // '&:hover': {
                                    //     backgroundColor: 'primary.main',
                                    //     color: 'white',
                                    //     '& img': {
                                    //         filter: 'invert(1)',         // Invierte los colores de la imagen al hacer hover
                                    //     }   // Color de fondo al pasar el ratón
                                    // }

                                }}
                                onClick={handleMenuClick}>
                                <img src={perfilIcon} alt="Perfil" style={{ width: '3rem', height: '3rem' }} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem onClick={() => {
                                    setOpenModalLogin(true)
                                    handleMenuClose()
                                }}>Iniciar Sesión</MenuItem>
                            </Menu>
                        </div>

                        {/* Botón Registrarme */}
                        <Button
                            variant="contained"
                            onClick={() => setOpenModalRegister(true)}
                            sx={{ width: '33%' }} // Clase CSS para animación
                        >
                            REGISTRARME
                        </Button>
                        <MenuAdminContainer/>
                    </Stack>
                </Grid>
            </Grid>
            <RegisterModal
                openModalRegister={openModalRegister}
                setopenModalRegister={setOpenModalRegister}
            />
            <LoginModal
                openModalLogin={openModalLogin}
                setopenModalLogin={setOpenModalLogin}
            />
            <PreOrderModal
                open={openModalPreOrder}
                handleClose={() => { setOpenModalPreOrder(false) }} />
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1300
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            )}
        </ThemeProvider>
    );
}
