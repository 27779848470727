import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import Box from '@mui/material';
import { Grid, IconButton, TextField, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { fetchAllClientsApi } from '../../Macrosoft_API/index'
import CircularProgress from '@mui/material/CircularProgress';
import RegisterModal from '../modalRegister';

export default function UserTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState('')
  const [arrayClients, setArrayClients] = useState([])
  const [rowsLength, setRowsLength] = useState(0)
  const [loading, setLoading] = useState(false)
  const [openModalRegister, setOpenModalRegister] = useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleGetApiUser(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleGetApiUser = async (page) => {
    setLoading(true)
    try {
      const response = await fetchAllClientsApi(page)
      console.log('respuesta del fetch users:', response)
      setArrayClients(response.data.data)
      setRowsLength(response.data.pages)
    } catch (error) {
      console.log('Hubo un error fetch: ', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    handleGetApiUser(1)
  }, [])
  useEffect(() => {
    console.log('Array clients: ', arrayClients)
  }, [arrayClients])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Grid item xs={12} sm={6} md={7} sx={{ display: 'flex' }}>

        <TextField
          sx={{ width: '35%', marginTop: '2rem' }}
          label="Buscador.."
          value={search}
          onChange={(e) => { setSearch(e.target.value) }}
        />
        <IconButton sx={{ margin: 2, marginTop: 5 }}>
          <AddIcon
            onClick={() => { setOpenModalRegister(true) }}
          />
        </IconButton>
      </Grid>
      <TableContainer sx={{ maxHeight: '70vh', minHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: '#E21B1B', color: 'white' }}>
                Nombre Fantasia
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: '#E21B1B', color: 'white' }}>
                Nombre
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: '#E21B1B', color: 'white' }}>
                Direccion Trabajo
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: '#E21B1B', color: 'white' }}>
                Direccion Particular
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: '#E21B1B', color: 'white' }}>
                Telefono Particular
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: '#E21B1B', color: 'white' }}>
                Telefono Trabajo
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: '#E21B1B', color: 'white' }}>
                Email
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrayClients?.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.NombreFantasia}</TableCell>
                <TableCell>{item.Nombre}</TableCell>
                <TableCell>{item.DireccionTrabajo}</TableCell>
                <TableCell>{item.DireccionParticular}</TableCell>
                <TableCell>{item.TelefonoParticular}</TableCell>
                <TableCell>{item.TelefonoTrabajo}</TableCell>
                <TableCell>{item.Email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsLength ? rowsLength : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1300
          }}
        >
          <CircularProgress color="success" />
        </Box>
      )}
      <RegisterModal
        openModalRegister={openModalRegister}
        setopenModalRegister={setOpenModalRegister}
      />
    </Paper>
  );
}
