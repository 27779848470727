import * as React from 'react';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ProductCard } from '../ProductCard';
import useMainContext from '../Context/useMainContext';
export const ShopMain = () => {

    const { articles } = useMainContext()
    useEffect(() => {
        console.log('data recibida en ShopMain ', articles)
    }, [articles])

    return (
        <>
            <Grid container sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>

                {
                    articles?.map(item => {
                        // console.log('item', item)
                        return (
                            <ProductCard key={item.CodArticulo} product={item} />
                        )
                    })
                }

            </Grid>
        </>
    )
}