import React, { createContext, useState } from 'react'
import { useEffect } from 'react'
export const Context = createContext()
const Provider = ({ children }) => {
    const testContext = 'Context Exitoso'
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [groups, setGroups] = useState([])
    const [articles, setArticles] = useState([])
    const [sectionSelected, setSectionSelected] = useState('Carrousel')
    const [arrayProductShop, setArrayProductShop] = useState([])
    const [primaryColor, setPrimaryColor] = useState('E21B1B')
    const value = {
        testContext,
        categories,
        setCategories,
        subCategories,
        setSubCategories,
        groups,
        setGroups,
        articles,
        setArticles,
        sectionSelected,
        setSectionSelected,
        sectionSelected,
        primaryColor, 
        setPrimaryColor,
        arrayProductShop, 
        setArrayProductShop
    }
    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}
export { Provider };
// eslint-disable-next-line import/no-anonymous-default-export
// export default {
//     Provider,
//     Consumer: Context.Consumer
// }