import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, ListItemIcon, Typography, Box, Collapse, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useMainContext from '../Context/useMainContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserContainer from './UsuariosContainer'
import GroupIcon from '@mui/icons-material/Group';
import CloseIcon from '@mui/icons-material/Close';
import ModalCategories from './AdminComponents/ModalCategories';
import CategoryIcon from '@mui/icons-material/Category';
function MenuAdminContainer() {
  const { subCategories, setSubCategories, setArticles, setShowMainCarrousel } = useMainContext();
  const [open, setOpen] = useState(false);
  const [openModalUserContainer, setOpenModalUserContainer] = useState(false)
  const [openCategory, setOpenCategory] = useState(null);
  const [loading, setLoading] = useState(false)
  const [adminCategories, setAdminCategories] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
    
  };

  // const handelModal = () => {
  //   setAdminCategories(true);
  // }


  // const handleSubCategoryClick = async (id_subCategory) => {
  //   setLoading(true);
  //   try {
  //     // const articles = await fetchArticlesBySubCategory(id_subCategory);
  //     // setArticles(articles);
  //     // setShowMainCarrousel(false)
  //     toggleDrawer()
  //   } catch (error) {
  //     console.log('Error fetching articles:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleClick =  () => {

    setAdminCategories(true);
    // try {
    //   // const response = await fetchSubCategories(id_category);
    //   // console.log('response articles: ', response);
    //   // setSubCategories(response);
    //   setOpenCategory(openCategory === id_category ? null : id_category);
    // } catch (error) {
    //   console.log('Hubo un error: ', error);
    // }
  };

  const closeModal = () => {
    setAdminCategories(!adminCategories);
  }

  return (

    <>
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: '25%',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',backgroundColor:'#E21B1B',padding:1 }}>
            <Typography 
            sx={{color:'white'}}
            variant='h4'>
              Panel Administrador
            </Typography>
            <CloseIcon
              sx={{ cursor: 'pointer' }}
              onClick={toggleDrawer} />
          </Grid>

          <List>
            {/* Alimentos */}
            <ListItem button 
            onClick={() => {
              setOpenModalUserContainer(true)
              toggleDrawer()
            }}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Gestión de Usuarios" />
            </ListItem>
            
            <ListItem
            button  
            // sx={{
            //   cursor:'pointer',
            //   transition: 'box-shadow 0.3s ease-in-out',
            //     '&:hover': {
            //       boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            //     }
            //   }} 
              onClick={() => handleClick()}>
              <ListItemIcon>
                <CategoryIcon/>
              </ListItemIcon>
              <ListItemText primary="Gestión de Categorías" />
            </ListItem>
     

          </List>
        </Box>
      </Drawer>
      <UserContainer
        openModal={openModalUserContainer}
        handleClose={() => { setOpenModalUserContainer(false) }}
      />
    </div>

    <ModalCategories open={adminCategories} handleClose={closeModal}></ModalCategories>
    </>
  );
}

export default MenuAdminContainer;
