import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';
import EditProductModal from './utils/EditProduct';
import axios from 'axios';
import useMainContext from './Context/useMainContext';
import { fetchImagesByArticle } from './controller/imageController'
const WhiteCheckbox = styled(Checkbox)({
    color: 'white',
    '&.Mui-checked': {
        color: 'white',
    },
});
const currency = 'U$S';

export function ProductCard({ product, imageUrl }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { arrayProductShop, setArrayProductShop } = useMainContext()
    const [checked, setChecked] = useState(false);
    const [image, setImage] = useState(null);
    const [imageLoad, setImageLoad] = useState(null)
    const [open, setOpen] = useState(false);
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    
    const handleCloseModalProduct = () => {
        setOpen(false)
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };
    const handleButtonClick = () => {
        setChecked(!checked);

    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('image', image);
        formData.append('CodArticulo', product.CodArticulo);

        try {
            const response = await axios.post(`${apiUrl}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                alert('Imagen subida con éxito');
                console.log(response.data);
            }
        } catch (error) {
            console.error('Error al subir la imagen:', error);
            alert('Hubo un error al subir la imagen');
        }
    };
    const handleAddProductCard = () => {
        const newArrayProduct = [...arrayProductShop]
        newArrayProduct.push(product)
        setArrayProductShop(newArrayProduct)
        console.log('array Product', newArrayProduct)
    }
    const removeProductFromCart = (productId) => {
        const newArrayProduct = arrayProductShop.filter(item => item.CodArticulo !== productId)
        console.log('newArray', newArrayProduct)
        setArrayProductShop(newArrayProduct)
    };
    useEffect(() => {
        const fetchImages = async () => {
            // console.log('que recibo de producto: ', product.CodArticulo);
            try {
                const response = await fetchImagesByArticle(product.CodArticulo);
                // console.log('respuesta del fetch: ', response);
                if (response?.length > 0) {
                    setImageLoad(response[0].urlImage)
                }
            } catch (error) {
                console.log('Hubo un error en el fetch ', error);
            }
        };

        if (product && product.CodArticulo) {
            fetchImages();
        }
    }, [product]);

    useEffect(() => {
        if (checked) {
            handleAddProductCard()
        } else {
            removeProductFromCart(product.CodArticulo)
        }
    }, [checked])
    useEffect(() => {
        if (arrayProductShop.some(item => item.CodArticulo === product.CodArticulo)) {
            setChecked(true);
        } else {
            setChecked(false)
        }
    }, [arrayProductShop, product.CodArticulo]);
    return (
        <Card sx={{ width: 300, maxWidth: 300, height: 350, maxHeight: 350, boxShadow: 'lg', margin: 1 }}>
            <CardOverflow>
                <AspectRatio sx={{ minWidth: 200 }}>
                    <img
                        src={imageLoad}
                        loading="lazy"
                        alt={product?.Descripcion || 'Sin imagen'}
                    />
                </AspectRatio>
            </CardOverflow>
            <CardContent>
                {/* <Typography level="body-xs" sx={{ mt: 1, fontWeight: 'xl' }}>{product.CodStock.Articulo}</Typography> */}
                <Typography level="title-lg" sx={{ mt: 1, fontWeight: 'xl' }}>
                    {currency} {product.precio}
                </Typography>
                <Typography level="body-sm">
                    {product.descripcion}
                </Typography>
            </CardContent>
            <CardOverflow>
                <EditIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => { setOpen(true) }}
                />
                {/* <div>
                    <input
                        type="file"
                        onChange={handleImageChange}
                        required
                    />
                </div> */}
                <Button
                    variant="solid"
                    size="lg"
                    // onClick={handleSubmit}
                    sx={{
                        backgroundColor: "#d6381b",
                        color: "#fff",  // Texto blanco
                        '&:hover': {
                            backgroundColor: "#C61818"  // Color cuando se hace hover
                        }
                    }}
                >
                    Agregar al Carrito
                    <WhiteCheckbox
                        {...label}
                        sx={{ left: "10%" }}
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                </Button>
            </CardOverflow>
            <EditProductModal open={open} handleClose={handleCloseModalProduct} />
        </Card>
    );
}
