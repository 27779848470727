import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
export const fetchImagesByArticle = async (articleCode) => {
    // console.log('recibo CodArticulo', articleCode)
    const params = new URLSearchParams();
    params.append('CodArticulo', articleCode);

    try {
        const response = await axios.get(`${apiUrl}/getImagesByArticle/${articleCode}`);
        // console.log("Images fetched:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching images:', error);
        throw error;
    }
}
