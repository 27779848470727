import { Box, Container, Grid, Typography, Link, Snackbar  } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelePhone from '../../src/erebil/Assets/telephone.svg';
import MobilePhone from '../../src/erebil/Assets/phone.svg';
import Company from '../../src/erebil/Assets/company.svg';
import Mail from '../../src/erebil/Assets/mail_2.svg';
import footerShapeImage from '../erebil/Assets/footer_shape.png';
import {React , useState} from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';


const ContactFooter = () => {

  const [alertOpen, setAlertOpen] = useState(false);

  const copyEmailToClipboard = async () => {
    try {
      await navigator.clipboard.writeText('ventas@erebil.com.uy');
      setAlertOpen(true);
    } catch (err) {
      console.error('Failed to copy email:', err);
      setAlertOpen(true);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Box
        component="footer"
        sx={{
          color: 'black',
          // py: 1,
          // mt: 4,
          py: { xs: 2, sm: 3, md: 4 },  // Padding adaptado a pantallas pequeñas
          mt: { xs: 2, sm: 3, md: 4 },
          boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
          backgroundImage: `url(${footerShapeImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Erebil</Typography>
            </Grid>
            <Grid item  
                      sx={{ 
                        marginTop: {
                          xs: '-7%',    // Para pantallas extra pequeñas (teléfonos)
                          sm: '3%',    // Para pantallas pequeñas (tablets)
                          md: '10%',   // Para pantallas medianas (laptops)
                          lg: '0%'    // Para pantallas grandes (monitores)
                        },
                        marginRight: {
                          xs: '22%',    // Para pantallas extra pequeñas (teléfonos)
                          sm: '20%',    // Para pantallas pequeñas (tablets)
                          md: '10%',   // Para pantallas medianas (laptops)
                              // Para pantallas grandes (monitores)
                        },
                        marginBottom: {
                          xs: '1rem'
                        }
                      }}
              >
              <Grid container sx={{ display: 'flex', ml: '7rem' }} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Link href="https://www.instagram.com/erebil.sa?igsh=MWh4d25zcGV2YnE4ag==" target="_blank" rel="noopener" color="inherit" underline="none">
                    <InstagramIcon fontSize="small" sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)' }} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://www.facebook.com/share/artpr1i2z4js3rBG/?mibextid=LQQJ4d" target="_blank" rel="noopener" color="inherit" underline="none">
                    <FacebookIcon fontSize="small" sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)' }} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://api.whatsapp.com/send?phone=59899669721" target="_blank" rel="noopener" color="inherit" underline="none">
                    <WhatsAppIcon fontSize="small" sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)' }} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center" justifyContent="space-between" marginTop={'0.1%'}  sx={{ 
                        marginTop: {
                          xs: '3%',    // Para pantallas extra pequeñas (teléfonos)
                          sm: '3%',    // Para pantallas pequeñas (tablets)
                          md: '10%',   // Para pantallas medianas (laptops)
                          lg: '0%'    // Para pantallas grandes (monitores)
                        } 
                      }} >
            <Link href="https://maps.app.goo.gl/miGDhJvC7jpJaabr6" target="_blank" rel="noopener" color="inherit" underline="none" sx={{ 
                        marginBottom: {
                          xs: '1rem',    // Para pantallas extra pequeñas (teléfonos)
                          sm: '3%',    // Para pantallas pequeñas (tablets)
                          md: '10%',   // Para pantallas medianas (laptops)
                          lg: '0%'    // Para pantallas grandes (monitores)
                        } 
                      }}>
              <Grid item sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 10px 5px rgba(0, 0, 0, 0.1)' }}>
                <img src={Company} alt="company" style={{ width: 30, height: {xs:20, sm: 23, md: 30, lg:30}, marginRight: '0.5rem' }} />
                <Typography variant="body1">Juan Paulllier 1178</Typography>
              </Grid>
            </Link>

            <Grid 
            // sx={{ mt:'2%', ml:'10%', padding:'-2%' }}
            sx={{ textAlign: { xs: 'center', sm: 'left' }, mt: { xs: 2, sm: -2 , lg: 2}, marginLeft: {
              sm: '12%',
              md: '5%'
            } }}
            >
            <Grid item sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '1%', boxShadow: '0 10px 5px rgba(0, 0, 0, 0.1)', marginBottom: {lg:'2rem', sm: '1rem', xs: '1rem'}, marginTop: {lg: '-3rem', xs: '-3.5rem', sm: '-5.5rem'} }}>
                <Typography variant="body1" >
                  <Grid container alignItems="center">
                    <img src={TelePhone} alt="telephone" style={{ width: 30, height: {xs:20, sm: 23, md: 30, lg:30}, marginRight: '1rem' }} />
                    <Grid sx={{ color: '#ff0000' }}>+598 2409 9877</Grid>
                  </Grid>
                </Typography>
              </Grid>
            
            
            
            <Grid item sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '4px solid #ffff', borderRadius: '5px', boxShadow: '0 10px 5px rgba(0, 0, 0, 0.1)' , marginBottom: {xs: '-1rem', sm: '-1rem'}} }>
            <Typography variant="body1">
                <Grid display="flex" alignItems="center" sx={{ marginTop: {sm: '1.5rem'} }}>
                  <img src={MobilePhone} alt="mail" style={{ width: 30, height: {xs:20, sm: 23, md: 30, lg:30}, marginRight: '0.5rem' }} />
                  <Grid rel="noopener" color="inherit" underline="none" sx={{ color: '#ff0000' }}>
                    +598 099 669 721
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            </Grid>

              <Grid item sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 10px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }}>
              <Typography variant="body1">
                <Grid display="flex" alignItems="center" onClick={copyEmailToClipboard}>
                  <img src={Mail} alt="mail" style={{ width: 30, height: 30, marginRight: '0.5rem' }} />
                  <Link rel="noopener" color="inherit" underline="none" sx={{ color: '#ff0000' }}>
                    ventas@erebil.com.uy
                  </Link>
                </Grid>
              </Typography>
            </Grid>

          </Grid>

          <Grid mt={'1rem'} ml={'1rem'} sx={{ 
            marginTop: {
              sm: '2rem',
              md: '3rem',
              lg: '3rem'
            },
            marginBottom : {
              lg: '1.5rem'
            }
           }}>
            <Typography variant="caption" align="center" mt={1} sx={{
              background: 'linear-gradient(to top, #27477A, #4B0082)',
              color: 'white',
              padding: '2px 4px',
              borderRadius: '2px',
              fontSize: '0.8rem',
              '&:hover': {
                  cursor: 'default'
              }
                }} marginTop={'1%'}>
              Copyright 2024 - Erebil S.A. by JAR Software
            </Typography>
          </Grid>
        </Container>
      </Box>
       <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ display: 'flex', margin: '0 auto', left: '1rem' }}
     >
        <Alert onClose={handleAlertClose} severity="success" 
        sx={{
            position: 'relative', 
            left: {lg:'-85%', xs: '-7rem', sm: '-4rem', md: '-4rem'}, 
            top: {lg: '25%', xs: '-3rem'},
            transform: 'translateX(-50%)', 
            }} 
        icon={ <CheckIcon fontSize="inherit" /> }>
          Email copiado!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactFooter;
