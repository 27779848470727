import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CarrouselMUI from './carrouselMUI'
import { ShopNavigator } from '../components/ShopNavigator/ShopNavigator';
import foto1 from './Assets/8.png'
import foto2 from './Assets/9.png'
import foto3 from './Assets/10.png'
import foto4 from './Assets/11.png'
import { height, width } from '@fortawesome/free-solid-svg-icons/fa0';
export default function Carrousel() {
    const images = [
        {
            label: 'San Francisco – Oakland Bay Bridge, United States',
            imgPath: foto1,
        },
        {
            label: 'Bird',
            imgPath: foto2,
        },
        {
            label: 'Bali, Indonesia',
            imgPath: foto3,
        },
        {
            label: 'Goč, Serbia',
            imgPath: foto4,
        },
    ];
    const size={
        height:'50vh',
        width:'100%'
    }
    return (
        <Box sx={{ flexGrow: 1, height: 100 }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', paddingX: '2rem' }}>
                <Grid item>
                    {/* <ShopNavigator /> */}
                    <CarrouselMUI images={images} size={size} />
                </Grid>
            </Grid>
        </Box>
    );
}
