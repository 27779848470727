import * as React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { MenuLat } from './MenuLat';
import { ShopMain } from './ShopMain';
export const ShopNavigator = () => {

    return (
        <>
            <Grid container sx={{ height: '100%', width: '100vw', border: '1px solid #cdcfcc',
                marginTop:{
                    xs:'',
                    sm:'5%',
                    md:'5%',

                }}}>
                <Grid sx={{ display: { xs: 'none',sm:'block' } }} item md={2}>
                    <MenuLat />
                </Grid>
                <Grid item md={9}>
                    <ShopMain />
                </Grid>
            </Grid>
        </>
    )
}