import * as React from 'react';
import { useState } from 'react';
import { Box, Button, Grid, FormControl, InputLabel, TextField, Select, MenuItem, Modal,Typography,IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../erebil/Assets/stylesFormRegister.css';
import { fetchRegisterUser } from './controller/userController';
import ModalAlert from './utils/modalAlert';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '55%',
  height: '85%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY: 'auto',
  p: 4,
  padding:0,
  borderRadius:'10px'
};

export default function RegisterModal({ openModalRegister, setopenModalRegister }) {
  const [open, setOpen] = useState(false);
  const [age, setAge] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [calle, setCalle] = useState('');
  const [esquina, setEsquina] = useState('');
  const [numeroPuerta, setNumeroPuerta] = useState('');
  const [modalAlert, setModalAlert] = useState()
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleRegister = async () => {
    // setModalAlert(true)
    const data = {
      username,
      password,
      mail: email,
      nombre,
      apellido,
      callePrincipal: calle,
      calleEsquina: esquina,
      numeroPuerta
    }
    console.log('data register:', data)
    const response = await fetchRegisterUser(data)
    if (response) {
      console.log('usuario creado con exito')
      setModalAlert(true)
      return
    } else {
      console.log('Hubo un error:', response)
    }
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setopenModalRegister(false);

  return (
    <div>
      <Modal
        open={openModalRegister}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid className='headerFormRegister'>
              <h2 className='tituloFormRegister'>
                FORMULARIO DE REGISTRO
              </h2>
              
            </Grid>
            {/* <Grid sx={{ width: '100%', backgroundColor: '#E21B1B', padding: '13px' }} container justifyContent="space-between" alignItems="center">
                                    <Typography sx={{ color: 'white' }} variant="h4" component="div" gutterBottom>
                                        Formulario de Registro
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid> */}
            <Grid className='containerFormRegister'>
              <Grid className='containerInputForm'>
                <TextField
                  id="username"
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="password"
                  label="Password"
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="nombre"
                  label="Nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="apellido"
                  label="Apellido"
                  value={apellido}
                  onChange={(e) => setApellido(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="calle"
                  label="Calle"
                  value={calle}
                  onChange={(e) => setCalle(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="esquina"
                  label="Esquina"
                  value={esquina}
                  onChange={(e) => setEsquina(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="numeroPuerta"
                  label="Numero de puerta"
                  value={numeroPuerta}
                  onChange={(e) => setNumeroPuerta(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="demo-simple-select-label">Seleccione el tipo de usuario</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Cliente</MenuItem>
                    <MenuItem value={20}>Proveedor</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Grid item>
                <Button
                  onClick={handleRegister}
                  variant='outlined' sx={{ margin: 5, padding: 2 }}>
                  Registrarme
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleClose} variant='outlined' sx={{ margin: 5, paddingX: 6, paddingY: 2 }}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ModalAlert
        open={modalAlert}
        handleClose={() => setModalAlert(false)}
      />
    </div>
  );
}
