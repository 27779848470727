import * as React from 'react';
import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Select, Menu, MenuItem, IconButton, Typography, Button, CircularProgress } from '@mui/material';
import styles from './Assets/styles.css'
import Box from '@mui/material/Box';

import monitorPng from './Assets/monitor.png'
import iconoCamara from './Assets/iconcam.png'
import vocero from './Assets/vocero.png'
import domotica from './Assets/domotica.png'
import pregunta from './Assets/pregunta.png'
import alarmas from './Assets/alarma.png'
import oferta from './Assets/venta.png'
import accesoriosCctv from './Assets/accesorios-cctv.png'
import otrosPng from './Assets/mas.png'
import { fetchAllSubCategoryByThirdFamily, fetchSubCategories, fetchArticles } from '../Macrosoft_API';
import useMainContext from '../components/Context/useMainContext';
// Define el tema personalizado con un color principal personalizado
const theme = createTheme({
    palette: {
        primary: {
            main: '#ff0000', // Define tu color principal aquí
        },
    },
});

export default function Categories() {
    const {
        categories,
        setCategories,
        subCategories,
        setSubCategories,
        groups,
        setGroups,
        articles,
        setArticles,
        setSectionSelected,
        sectionSelected
    } = useMainContext()
    const [loading, setLoading] = useState(false)
    const testIcon = () => {
        console.log('test icon')
    }
    const handleGetGroups = async (categorie, group) => {
        setSectionSelected('ShopNavigator')
        console.log('funciona el icon')
        console.log('subFamily: ', categorie)
        console.log('group: ', group)
        try {
            const params = {
                subFamily: categorie,
                group: group,
                listaPrecio: '1',
                page: '1'
            }
            const response = await fetchAllSubCategoryByThirdFamily(params)
            console.log('respuesta de Handle Get Groups: ', response)
            // console.log('respuesta desde el fetch:', response)
            if (response?.data) {
                setGroups(response.data)
                console.log('Respuesta de obtener los productos', response.data)
            }
            return response.data
        } catch (error) {
            console.log('hubo un error en el fetch:', error)
        }
    }
    const handleGetSubCategories = async (categorie) => {
        try {
            const params = {
                categories: categorie,
                listaPrecio: '1',
                page: '1'
            }
            const response = await fetchSubCategories(params)
            if (response?.data) {
                setSubCategories(response.data)
                return response.data
            }
        } catch (error) {
            console.log('hubo un error en el fetch:', error)
        }
    }
    const handleGetArticles = async (subcategorie) => {
        console.log('funciona get Articles ', subcategorie)
        try {
            const params = {
                description: '-',
                CodStock: '-',
                CodCliente: '101',
                family: subcategorie,
                group: '-',
                page: '1'
            }
            const response = await fetchArticles(params)

            console.log('respuesta de obtener los articulos:', response)
            if (response) {
                setArticles(response)
                return response
            }
        } catch (error) {
            console.log('hubo un error en el fetch:', error)
        }
    }
    const handleGetAllData = async (categorie, subcategorie) => {
        setLoading(true)
        setCategories(subcategorie)
        const subcategories = await handleGetSubCategories(subcategorie)
        const productCategorie = await handleGetGroups(subcategorie, categorie)
        console.log('SUB CATEGORIES DESDE EL HANDLE:--->', subcategories)
        console.log(' productCategorie DESDE EL HANDLE:--->', productCategorie)
        handleGetArticles(subcategorie)
        setLoading(false)
    }
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100%' }}>
                <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                    <nav>
                        <ul class="menu">
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('15.1', 15) }}
                                    class="iconTransform" width="3rem" height="3rem">
                                    <image href={iconoCamara} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">DAHUA</a>
                                    {/* <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul> */}
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('AUDIO ITC', 14) }}
                                    class="iconTransform" width="3rem" height="3rem">
                                    <image href={vocero} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">AUDIO</a>
                                    {/* <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul> */}
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('TUYA SMART', 17) }}
                                    class="iconTransform" width="3rem" height="3rem">
                                    <image href={domotica} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">DOMOTICA</a>
                                    {/* <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul> */}
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('GCN', 20) }}
                                    class="iconTransform" width="3rem" height="3rem">
                                    <image href={pregunta} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">INTRUSION</a>
                                    {/* <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul> */}
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('Opticon', 19) }}
                                    class="iconTransform" width="3rem" height="3rem">
                                    <image href={monitorPng} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">DISPLAY<br />ELECTRONICO</a>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('EAS', 2) }}
                                    class="iconTransform" width="3rem" height="3rem">
                                    <image href={alarmas} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">ALARMAS<br /> COMERCIALES</a>
                                    {/* <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul> */}
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('Ofertas', 22) }}
                                    class="iconTransform" width="3rem" height="3rem">

                                    <image href={oferta} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">OFERTAS</a>
                                    {/* <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul> */}
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('Accesorio CCTV', 21) }}
                                    class="iconTransform" width="3rem" height="3rem">
                                    <image href={accesoriosCctv} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">Accesorios CCTV</a>
                                    {/* <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul> */}
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('Accesorio CCTV', 21) }}
                                    class="iconTransform" width="3rem" height="3rem">
                                    <image href={otrosPng} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">Otros</a>
                                    <ul class="submenu">
                                        <li onClick={() => { handleGetAllData('Accesorio CCTV', 16) }}><a class="textoHover" href="#">ALPA</a></li>
                                        <li onClick={() => { handleGetAllData('Accesorio CCTV', 18) }}><a class="textoHover" href="#">IMOU</a></li>
                                        <li onClick={() => { handleGetAllData('Accesorio CCTV', 4) }}><a class="textoHover" href="#">PROMOLUX</a></li>
                                        <li onClick={() => { handleGetAllData('Accesorio CCTV', 9) }}><a class="textoHover" href="#">SEKURE</a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </Grid>

            </Box>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1300
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            )}
        </ThemeProvider>
    );
}
